.socialwork {
  min-height: 100vh;
  width: 100%;
}
.socialwork .socialbox {
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8901960784) 50%, rgba(0, 0, 0, 0.1843137255));
  height: 100vh;
  margin: auto;
  position: relative;
}
.socialwork .socialbox:nth-child(even) {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1843137255), rgba(0, 0, 0, 0.8901960784) 50%);
}
.socialwork .socialbox:nth-child(even) .content-box {
  flex-direction: row-reverse;
}
.socialwork .socialbox .bg {
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.socialwork .socialbox .content-box {
  max-width: var(--wl);
  height: 100%;
  margin: auto;
  display: flex;
  padding: 24px;
}
.socialwork .socialbox .content-box .content {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  color: var(--white);
}
.socialwork .socialbox .content-box .content .social-heading {
  font-size: 30px;
  color: var(--white);
}
.socialwork .socialbox .content-box .content .social-body {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  word-spacing: 3px;
  color: var(--white);
  font-weight: 200;
}
.socialwork .socialbox .content-box .content .social-body a {
  color: var(--accent);
}
.socialwork .socialbox .content-box .content p h2 {
  color: var(--white) !important;
}
.socialwork .socialbox .content-box .content .counter {
  width: auto;
  height: auto;
  display: flex;
  gap: 40px;
  align-items: left;
  justify-content: space-between;
}
.socialwork .socialbox .content-box .content .counter .counter_wrapper {
  height: -moz-fit-content;
  height: fit-content;
}
@media only screen and (max-width: 600px) {
  .counter {
    flex-wrap: wrap !important;
    width: 100% !important;
    gap: 15px !important;
    justify-content: space-evenly !important;
    align-items: stretch !important;
  }
  .counter .counter_wrapper {
    width: 40% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .counter .counter_wrapper .Counter_top span {
    text-align: center !important;
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .socialbox {
    background: rgba(0, 0, 0, 0.6) !important;
    height: 100vh !important;
  }
  .socialbox .content-box {
    flex-direction: column !important;
    justify-content: center;
  }
  .socialbox .content-box .content {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
  }
  .socialbox .content-box .content h2 {
    width: 100%;
    text-align: center;
  }
  .socialbox .content-box .content p {
    text-align: center;
  }
  .socialbox .content-box .content ul {
    list-style: none;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    color: var(--accent);
    text-align: center;
  }
  .socialbox .content-box .content ul li {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    margin: 3px;
    padding: 3px 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.349);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: var(--white);
  }
}
@media only screen and (max-width: 700px) {
  .content {
    width: 100% !important;
  }
  .content h2 {
    font-size: 22px !important;
  }
  .content p, .content li {
    font-size: 13px !important;
  }
  .content ul {
    font-size: 16px !important;
  }
  .socialbox {
    border-bottom: 1px solid var(--accent);
  }
  .socialbox:nth-child(1) {
    height: 120vh !important;
  }
  .socialbox:nth-child(2) {
    height: 130vh !important;
  }
  .socialbox:nth-child(7) {
    height: 140vh !important;
  }
  .socialbox:nth-child(12) {
    height: 130vh !important;
  }
  .socialbox:nth-child(16) {
    height: 170vh !important;
  }
  .socialbox:nth-child(17) {
    height: 180vh !important;
  }
  .socialbox:nth-child(18) {
    height: 140vh !important;
  }
}
.box1 .bg {
  background: url("../images/social/akka.webp");
}

.box2 .bg {
  background: url("../images/social/drushti1.webp");
}

.box3 .bg {
  background: url("../images/social/drushti2.webp");
}

.box4 .bg {
  background: url("../images/social/drushti3.webp");
}

.box5 .bg {
  background: url("../images/social/drushti4.webp");
}

.box6 .bg {
  background: url("../images/social/projectanandi.webp");
}

.box7 .bg {
  background: url("../images/social/vaccination1.webp");
}

.box8 .bg {
  background: url();
}

.box9 .bg {
  background: url("../images/social/drushti.webp");
}

.box10 .bg {
  background: url("../images/social/art2.webp");
}

.box11 .bg {
  background: url("../images/social/shivjayanti2018.webp");
}

.box12 .bg {
  background: url("../images/social/harit.webp");
}

.box13 .bg {
  background: url("../images/social/shivjayanti2020.webp");
}

.box14 .bg {
  background: url("../images/social/shivjayanti2021.webp");
}

.box15 .bg {
  background: url("../images/social/shivjayanti2022.webp");
}

.box16 .bg {
  background: url("../images/social/shivjayanti2023.webp");
}

.box17 .bg {
  background: url("../images/social/vaccination.webp");
}

.box18 .bg {
  background: url("../images/social/covid-kit.webp");
}

.box19 .bg {
  background: url("../images/social/farmer.webp");
}

.box20 .bg {
  background: url("../images/social/akka1.webp");
}/*# sourceMappingURL=socialwork.css.map */