.about-parent-v1 {
  width: 100%;
  background: var(--white);
}
.about-parent-v1 .about-container {
  height: auto;
  margin: auto;
  max-width: var(--wl);
  display: flex;
  justify-content: space-evenly;
  padding: 0px 15px;
}
.about-parent-v1 .about-container .left {
  height: 100%;
  width: 55%;
  color: var(--black);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 100px 0px 30px 0px;
}
.about-parent-v1 .about-container .left h1 {
  color: var(--accent);
  font-size: 2.5rem;
}
.about-parent-v1 .about-container .left .card-parent {
  width: 100%;
}
.about-parent-v1 .about-container .left .card-parent .card-container {
  display: flex;
  flex-direction: column;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card {
  padding: 15px;
  border: 1px solid var(--dark);
  flex-grow: 1;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card:hover {
  border: 1px solid var(--accent);
}
.about-parent-v1 .about-container .left .card-parent .card-container .card .card-headding {
  padding: 5px 0px;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card .card-headding h2 {
  font-size: 18px;
  font-weight: bold;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card .card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card .card-text .card-text-info {
  display: flex;
  width: 100%;
  gap: 50px;
  margin-bottom: 7px;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card .card-text .card-text-info .name-title {
  font-weight: bold;
  left: 0px !important;
  justify-content: space-around;
  min-width: 100px;
}
.about-parent-v1 .about-container .left .card-parent .card-container .card .card-text .card-text-info p {
  font-size: 14px;
}
.about-parent-v1 .about-container .left .card-parent .card-container .btncards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.about-parent-v1 .about-container .left .card-parent .card-container .btncards .dbtn a {
  text-decoration: none !important;
  color: var(--white);
  justify-content: center;
  display: flex;
}
.about-parent-v1 .about-container .left .card-parent .card-container .btncards svg {
  font-size: 18px;
}
.about-parent-v1 .about-container .left .card-parent .gap1 {
  height: 20px;
}
.about-parent-v1 .about-container .left .left-content p {
  text-align: justify;
}
.about-parent-v1 .about-container .right {
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 0px 30px 30px;
}
.about-parent-v1 .about-container .right .right-container {
  border-radius: 25px;
  border: 1px solid white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.about-parent-v1 .about-container .right .right-container img {
  width: 500px;
  height: auto;
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.112);
}
.about-parent-v1 .legacy-container {
  height: auto;
  max-width: var(--wl);
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}
.about-parent-v1 .legacy-container .legacy {
  height: 100%;
  width: 90%;
  color: var(--black);
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 10px;
  padding: 0px 0px 40px 0px;
}
.about-parent-v1 .legacy-container .legacy h2 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .about-parent-v1 .about-container {
    margin-top: 50px;
    flex-direction: column-reverse;
  }
  .about-parent-v1 .about-container .left {
    height: 40%;
    width: 100%;
    align-items: center;
    padding: 0px 0px 0px 0px;
  }
  .about-parent-v1 .about-container .right {
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 40px auto 40px auto;
    overflow: hidden;
  }
  .block-parent .block-container .block1 .block-content {
    width: 95% !important;
    border-top: 1px solid var(--dark);
  }
  .block-parent .block-container .block2 .block-content {
    width: 95% !important;
    border-top: 1px solid var(--dark);
  }
  .block-parent .block-container .block3 .block-content {
    width: 95% !important;
    border-top: 1px solid var(--dark);
  }
}
.block-parent .block-container {
  padding: 0px 15px;
  justify-content: space-between;
}
.block-parent .block-container .block1 {
  height: 100%;
  color: var(--black);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 30px 0px 30px 30px;
}
.block-parent .block-container .block1 .block-content {
  width: 40%;
  border-top: 1px solid var(--dark);
}
.block-parent .block-container .block1 .block-content h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5rem;
}
.block-parent .block-container .block1 .block-content p {
  line-height: 25px;
}
.block-parent .block-container .block2 {
  height: 100%;
  color: var(--black);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 30px 0px 30px 30px;
}
.block-parent .block-container .block2 .block-content {
  width: 40%;
  border-top: 1px solid var(--dark);
}
.block-parent .block-container .block2 .block-content h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5rem;
}
.block-parent .block-container .block3 {
  height: 100%;
  color: var(--black);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 30px 0px 30px 30px;
}
.block-parent .block-container .block3 .block-content {
  width: 40%;
  border-top: 1px solid var(--dark);
}
.block-parent .block-container .block3 .block-content h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 1100px) {
  .legacy-container {
    display: flex;
    padding: 0px 15px;
    justify-content: space-evenly;
  }
  .legacy-container .legacy {
    height: 100%;
    width: 90%;
    color: var(--black);
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 10px;
    padding: 0px 0px 40px 0px;
  }
  .legacy-container .legacy h2 {
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 1100px) {
  .legacy-container .legacy {
    width: 100% !important;
    margin: 30px 0px 0px 0px;
  }
}
@media only screen and (max-width: 500px) {
  .legacy-container .legacy {
    width: 100% !important;
    margin: 30px 0px 0px 0px;
  }
  .legacy-container .legacy h2 {
    text-align: center;
  }
}/*# sourceMappingURL=about.css.map */