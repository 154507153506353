.counter_wrapper {
  width: -moz-fit-content;
  width: fit-content;
  height: 150px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.counter_wrapper .Counter_top span {
  font-size: 40px;
  color: #f97d09;
  font-weight: 600;
}
.counter_wrapper .Counter_top .Counter_sufix {
  font-size: 30px;
}
.counter_wrapper .Counter_p {
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .counter_wrapper {
    width: 80%;
    height: 160px;
  }
}/*# sourceMappingURL=countersocial.css.map */