//////////////////////////////////////////////

//about.scss//
.about-parent-v1 {
  // height: 120vh;
  width: 100%;
  background: var(--white);

  .about-container {
    height: auto;

    margin: auto;
    max-width: var(--wl);
    display: flex;
    justify-content: space-evenly;
    padding: 0px 15px;
    // justify-content: space-evenly;

    .left {
      height: 100%;
      width: 55%;
      color: var(--black);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 100px 0px 30px 0px;

      h1 {
        color: var(--accent);
        font-size: 2.5rem;
      }

      .card-parent {
        width: 100%;
        .card-container {
          display: flex;
          flex-direction: column;

          .card {
            padding: 15px;
            border: 1px solid var(--dark);
            flex-grow: 1;

            &:hover {
              border: 1px solid var(--accent);
            }

            .card-headding {
              padding: 5px 0px;

              h2 {
                font-size: 18px;
                font-weight: bold;
              }
            }

            .card-text {
              display: flex;
              flex-direction: column;
              // justify-content: space-between;\
              justify-content: space-around;

              .card-text-info {
                display: flex;
                width: 100%;
                // justify-content: space-between;
                gap: 50px;
                margin-bottom: 7px;

                .name-title {
                  font-weight: bold;
                  left: 0px !important;
                  justify-content: space-around;
                  min-width: 100px;
                }

                p {
                  font-size: 14px;
                }
              }
            }
          }

          .btncards {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .dbtn a {
              text-decoration: none !important;
              color: var(--white);
              justify-content: center;
              display:flex;
            }
            // padding: 10px 50px;
            svg {
              font-size: 18px;
            }
          }
        }

        .gap1 {
          height: 20px;
        }
      }

      .left-content {
        p {
          text-align: justify;
        }
      }
    }

    .right {
      height: 100%;
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 150px 0px 30px 30px;

      .right-container {
        border-radius: 25px;
        border: 1px solid white;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          width: 500px;
          height: auto;
          border-radius: 20px;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.112);
        }
      }
    }
  }

  .legacy-container {
    height: auto;
    max-width: var(--wl);
    margin: auto;
    display: flex;
    // padding: 0px 15px;
    justify-content: space-evenly;

    .legacy {
      height: 100%;
      width: 90%;
      color: var(--black);
      display: flex;
      flex-direction: column;
      text-align: justify;
      gap: 10px;
      padding: 0px 0px 40px 0px;

      h2 {
        font-size: 25px;
        font-weight: bold;
      }
    }
  }
  // .legacy-container {
  //         display: flex;
  //         padding: 0px 15px;
  //         justify-content: space-evenly;

  //         .legacy {
  //             height: 100%;
  //             width: 90%;
  //             color: var(--black);
  //             display: flex;
  //             flex-direction: column;
  //             text-align: justify;
  //             gap: 10px;
  //             padding: 0px 0px 40px 0px;

  //             h2 {
  //                 font-size: 18px;
  //                 font-weight: bold;
  //             }
  //         }
  // }
}

@media only screen and (max-width: 1100px) {
  .about-parent-v1 {
    .about-container {
      margin-top: 50px;
      flex-direction: column-reverse;

      .left {
        height: 40%;
        width: 100%;
        align-items: center;
        padding: 00px 0px 0px 0px;
      }

      .right {
        height: 100%;
        width: 100%;
        padding: 0px;
        // margin: 100px 0px 0px 0px;
        margin: 40px auto 40px auto;
        overflow: hidden;
      }
    }
  }

  .block-parent .block-container .block1 .block-content {
    width: 95% !important;
    border-top: 1px solid var(--dark);
  }

  .block-parent .block-container .block2 .block-content {
    width: 95% !important;
    border-top: 1px solid var(--dark);
  }

  .block-parent .block-container .block3 .block-content {
    width: 95% !important;
    border-top: 1px solid var(--dark);
  }
}

.block-parent {
  // height: 70vh;

  .block-container {
    // display:flex;
    padding: 0px 15px;
    justify-content: space-between;

    .block1 {
      height: 100%;
      // width: 40%;
      color: var(--black);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 30px 0px 30px 30px;

      .block-content {
        width: 40%;
        border-top: 1px solid var(--dark);

        h2 {
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 1.5rem;
        }

        p {
          line-height: 25px;
        }
      }
    }

    .block2 {
      height: 100%;

      color: var(--black);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 30px 0px 30px 30px;
      // border-top:1px solid var(--dark);

      .block-content {
        width: 40%;
        border-top: 1px solid var(--dark);

        h2 {
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 1.5rem;
        }
      }
    }

    .block3 {
      height: 100%;

      color: var(--black);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 30px 0px 30px 30px;
      // border-top:1px solid var(--dark);

      .block-content {
        width: 40%;
        border-top: 1px solid var(--dark);

        h2 {
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .legacy-container {
    display: flex;
    padding: 0px 15px;
    justify-content: space-evenly;

    .legacy {
      height: 100%;
      width: 90%;
      color: var(--black);
      display: flex;
      flex-direction: column;
      text-align: justify;
      gap: 10px;
      padding: 0px 0px 40px 0px;

      h2 {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .legacy-container {
    .legacy {
      width: 100% !important;
      margin: 30px 0px 0px 0px;
    }
  }
}
@media only screen and (max-width: 500px) {
  .legacy-container {
    .legacy {
      width: 100% !important;

      margin: 30px 0px 0px 0px;
      h2 {
        text-align: center;
      }
    }
  }
}
