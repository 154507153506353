.bloglayout {
  height: auto;
  width: 100%;
  background: rgb(210, 210, 210);
}
.bloglayout .blog-container {
  max-width: var(--wl);
  margin: auto;
  height: 100%;
  background: rgb(210, 210, 210);
  padding: 100px 0px 50px 0px;
}
.bloglayout .blog-container .layout_blog {
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.bloglayout .blog-container .layout_blog .layout_top {
  display: flex;
  height: 400px;
  gap: 20px;
  align-items: center;
}
.bloglayout .blog-container .layout_blog .layout_top .layout_img {
  background: rgb(198, 198, 198);
  width: 70%;
  border-radius: 10px;
  height: 400px;
}
.bloglayout .blog-container .layout_blog .layout_top .layout_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.bloglayout .blog-container .layout_blog .layout_top .layout_details {
  height: 400px;
  width: 30%;
  border: 2px solid var(--accent);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.bloglayout .blog-container .layout_blog .layout_top .layout_details p {
  width: 100%;
  border-radius: 5px;
  background: rgb(234, 234, 234);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
.bloglayout .blog-container .layout_blog .layout_bottom {
  height: calc(100% - 400px);
  overflow-x: hidden;
}
.bloglayout .blog-container .layout_blog .layout_bottom h1 {
  font-size: 40px;
  padding: 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.327);
  margin-bottom: 20px;
}

@media only screen and (max-width: 1100px) {
  .bloglayout .blog-container .layout_blog .layout_top {
    flex-direction: column;
    height: 500px;
    gap: 20px;
  }
  .bloglayout .blog-container .layout_blog .layout_top .layout_img {
    width: 100%;
  }
  .bloglayout .blog-container .layout_blog .layout_top .layout_details {
    height: 80px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    flex-direction: row;
  }
  .bloglayout .blog-container .layout_blog .layout_top .layout_details p {
    width: 40%;
  }
  .bloglayout .blog-container .layout_blog .layout_bottom {
    height: auto;
  }
}
@media only screen and (max-width: 700px) {
  .layout_details {
    height: auto !important;
    width: 100%;
    flex-wrap: wrap !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout_details .layout_date,
  .layout_details .layout_time {
    width: 250px !important;
  }
}
@media only screen and (max-width: 900px) {
  .blog_btn {
    width: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}/*# sourceMappingURL=bloglayout.css.map */