.carousel_parent {
    width: 100%;
    height: 100vh;
    background: #00000093;
    position: relative;

    .slides {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        background: url("../images/herosection/hero7.webp");
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        transition: all 0.3s ease-out;

    }


    .overlay-top {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.298);
        z-index: 100;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .o_inner_box {
            --border: 3px solid rgb(255, 255, 255);

            height: 200px;
            width: 600px;
            display: flex;
            flex-direction: column;

            .otop {
                height: 120px;
                border-top: var(--border);
                border-left: var(--border);
                border-right: var(--border);
                position: relative;

                .textslide {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    color: white;
                    padding: 30px;
                    height: 100%;
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    font-size: 30px;
                    align-items: center;
                    font-weight: 900;
                    overflow: hidden;

                    span {
                        font-size: 25px;
                    }

                    &:nth-child(1) {
                        opacity: 1;
                        z-index: 110;

                    }

                    &:nth-child(2) {
                        opacity: 0;
                        z-index: 105;
                    }

                    &:nth-child(3) {
                        opacity: 0;
                        z-index: 105;
                    }
                    &:nth-child(4) {
                        opacity: 0;
                        z-index: 105;
                    }
                }
            }

            .obottom {
                height: 40px;
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                z-index: 200;

                .oline1 {
                    height: 3px;
                    width: 20%;
                    background: white;
                }

                .onav {
                    width: 30%;
                    height: 40px;

                    transform: translateY(-50%);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    span {
                        height: 20px;
                        width: 20px;
                        background: white;
                        display: block;
                        content: '';
                        border-radius: 300px;
                        cursor: pointer;
                        transition: var(--quick);

                        &:hover {
                            transform: scale(1.1);
                        }

                        &:nth-child(1) {
                            background: var(--accent);
                        }
                    }
                }

                .oline2 {
                    height: 3px;
                    width: 50%;
                    background: rgb(255, 255, 255);
                }
            }




        }
    }
}

.toggledclass2 {

    .slides {
        background: url("../images/herosection/hero4.webp");


    }


    .overlay-top .o_inner_box .otop .textslide {

        &:nth-child(1) {
            opacity: 0;
            z-index: 104;

        }

        &:nth-child(2) {
            opacity: 1;
            z-index: 110;
        }

        &:nth-child(3) {
            opacity: 0;
            z-index: 105;
        }
        &:nth-child(4) {
            opacity: 0;
            z-index: 110;
        }

    }

    .overlay-top .o_inner_box .obottom .onav span {
        &:nth-child(1) {
            background: white;
        }

        &:nth-child(2) {
            background: var(--accent) !important;
        }
    }

}

.toggledclass3 {

    .slides {
        background: url("../images/herosection/hero5.webp");

    }


    .overlay-top .o_inner_box .otop .textslide {

        &:nth-child(1) {
            opacity: 0;
            z-index: 104;

        }

        &:nth-child(2) {
            opacity: 0;
            z-index: 105;
        }

        &:nth-child(3) {
            opacity: 1;
            z-index: 110;
        }
        &:nth-child(4) {
            opacity: 0;
            z-index: 110;
        }
    }

    .overlay-top .o_inner_box .obottom .onav span {
        &:nth-child(1) {
            background: white;
        }

        &:nth-child(2) {
            background: white !important;
        }

        &:nth-child(3) {
            background: var(--accent) !important;
        }
        &:nth-child(4) {
            background: white !important;
        }
    }

}
.toggledclass4 {

    .slides {
        background: url("../images/herosection/hero6.webp");


    }


    .overlay-top .o_inner_box .otop .textslide {

        &:nth-child(1) {
            opacity: 0;
            z-index: 104;

        }

        &:nth-child(2) {
            opacity: 0;
            z-index: 110;
        }

        &:nth-child(3) {
            opacity: 0;
            z-index: 105;
        }
        &:nth-child(4) {
            opacity: 1;
            z-index: 105;
        }

    }

    .overlay-top .o_inner_box .obottom .onav span {
        &:nth-child(1) {
            background: white;
        }

        &:nth-child(4) {
            background: var(--accent) !important;
        }
    }

}



@media only screen and (max-width: 850px) {
    .o_inner_box {
        width: 500px !important;

        .obottom {
            position: relative !important;
            width: 100% !important;

            .oline1 {
                width: 10%;
            }

            .onav {
                width: 60%;
            }

            .oline2 {
                width: 30%;
            }

        }

    }
}


@media only screen and (max-width: 600px) {
    .o_inner_box {
        width: 90% !important;

    }
}

@media only screen and (max-width: 850px) and (min-width: 610px) {
    .o_inner_box {
        width: 90% !important;
        height: 100%;

    }
}

// @media only screen and (max-width: 1200px) {
//     .carousel_parent {
//         height: 80vh;
//     }
// }

@media only screen and (max-width: 900px) {
    .carousel_parent {
        height: 90vh;
    }
}

@media only screen and (max-width: 750px) {
    .carousel_parent {
        height: 80vh;
    }
}

@media only screen and (max-width: 600px) {
    .carousel_parent {
        height: 70vh;
    }
}

@media only screen and (max-width: 400px) {
    .carousel_parent {
        height: 65vh;
    }
}