.counter_wrapper {
    width: fit-content;
    height: 150px;
    // backdrop-filter: blur(10px);
    // background: rgba(255, 255, 255, 0.1);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 10px;
    // border: 1px solid rgba(255, 255, 255, 0.153);
    position: relative;
    overflow: hidden;
    .Counter_top{
        span {
            font-size: 40px;
            color:#f97d09;
            font-weight:600;
            

            
        }
        .Counter_sufix{
            font-size: 30px;
        }
        
    }

    .Counter_p{
        font-weight: 500;
    }
}

@media only screen and (max-width: 500px) {
    .counter_wrapper {
        width: 80%;
        height:160px;
}
} 