.socialwork {
    min-height: 100vh;
    width: 100%;
    // overflow-y: scroll;
    // scroll-snap-type: y mandatory;

    .socialbox {
        width: 100%;
        background: linear-gradient(90deg, #000000e3 50%, #0000002f);
        height: 100vh;
        margin: auto;
        position: relative;
        // scroll-snap-align: start;
        // position: relative;

        &:nth-child(even) {
            background: linear-gradient(90deg, #0000002f, #000000e3 50%);

            .content-box {
                flex-direction: row-reverse;
            }
        }


        .bg {
            height: 100%;
            width: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: -1;

        }

        .content-box {
            max-width: var(--wl);

            height: 100%;
            margin: auto;
            display: flex;
            padding: 24px;

            .content {
                display: flex;
                width: 60%;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                color: var(--white);

                .social-heading {
                    font-size: 30px;
                    color: var(--white);
                }

                .social-body {
                    font-size: 14px;
                    letter-spacing: 1px;
                    line-height: 20px;
                    word-spacing: 3px;
                    color: var(--white);
                    font-weight: 200;

                    a {
                        color: var(--accent);
                    }

                }

                p h2 {
                    color: var(--white) !important;
                }

                .counter {
                    width: auto;
                    height: auto;
                    display: flex;
                    gap: 40px;
                    align-items: left;
                    justify-content: space-between;

                    .counter_wrapper {
                        height: fit-content;
                    }
                }
            }

            .blank {}
        }
    }

}

@media only screen and (max-width:600px){
    .counter{
    flex-wrap: wrap !important;
    width:100% !important;
    gap:15px !important;
    justify-content: space-evenly !important;
    align-items: stretch !important;
    .counter_wrapper{
    width:40% !important;
  display: flex;
  flex-direction: column;
  align-items: center;

    .Counter_top 
    {

        span 
        {
            text-align: center !important;
            font-size: 30px !important;
        }
    }
    }
}

}

@media only screen and (max-width: 1200px) {

    .socialbox {
        background: rgba(0, 0, 0, 0.600) !important;
        height: 100vh !important;


        .content-box {
            flex-direction: column !important;
            justify-content: center;

            .content {
                width: 100% !important; 
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                flex-direction: column !important;
                
                h2 
                {
                    width: 100%;
                    text-align: center;
                }
                p 
                {
                    text-align: center;
                }
                ul 
                {
                    list-style: none;
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                    flex-direction: column;  
                    color: var(--accent);
                    text-align: center;
                    li 
                    {
                        text-align: center;
                        background: rgba(255,255,255,0.1);
                        margin: 3px;
                        padding: 3px 10px;
                        border-radius: 5px;
                        border: 1px solid rgba(255, 255, 255, 0.349);
                        backdrop-filter: blur(5px);
                        color: var(--white);
                    }
                
                }
            }

        }
    }

}


@media only screen and (max-width: 700px) { 
    .content {
        width: 100% !important; 

        
        h2 
        {
            font-size: 22px !important;
        }
        p,li
        {
            font-size: 13px !important;
        }
        ul 
        {
            font-size: 16px !important;
        }
     
    }
    .socialbox 
    {
     
        border-bottom: 1px solid var(--accent);
        &:nth-child(1)
        {
            height: 120vh !important;
       
        }
        &:nth-child(2)
        {
            height: 130vh !important;
       
        }
        &:nth-child(7)
        {
            height: 140vh !important;
            
       
        }
        &:nth-child(12)
        {
            height: 130vh !important;
        
        }
        &:nth-child(16)
        {
            height: 170vh !important;
        
        }
        &:nth-child(17)
        {
            height: 180vh !important;
        }
        &:nth-child(18)
        {
            height: 140vh !important;
        }
    }
}

.box1 .bg {
    background: url('../images/social/akka.webp');
}

.box2 .bg {
    background: url('../images/social/drushti1.webp');
}

.box3 .bg {
    background: url('../images/social/drushti2.webp');
}

.box4 .bg {
    background: url('../images/social/drushti3.webp');
}

.box5 .bg {
    background: url('../images/social/drushti4.webp');
}

.box6 .bg {
    background: url('../images/social/projectanandi.webp');
}

.box7 .bg {
    background: url('../images/social/vaccination1.webp');
}

.box8 .bg {
    background: url();
}

.box9 .bg {
    background: url('../images/social/drushti.webp');
}

.box10 .bg {
    background: url('../images/social/art2.webp');
}

.box11 .bg {
    background: url('../images/social/shivjayanti2018.webp');
}

.box12 .bg {
    background: url('../images/social/harit.webp');
}

.box13 .bg {
    background: url('../images/social/shivjayanti2020.webp');
}

.box14 .bg {
    background: url('../images/social/shivjayanti2021.webp');
}

.box15 .bg {
    background: url('../images/social/shivjayanti2022.webp');
}

.box16 .bg {
    background: url('../images/social/shivjayanti2023.webp');
}

.box17 .bg {
    background: url('../images/social/vaccination.webp');
}

.box18 .bg {
    background: url('../images/social/covid-kit.webp');
}

.box19 .bg {
    background: url('../images/social/farmer.webp');
}

.box20 .bg {
    background: url('../images/social/akka1.webp');
}