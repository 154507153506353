.change_button {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10000000000000000;

    button {
        height: 100%;
        width: 100%;
        cursor: pointer;
        font-weight: 500;
        border-radius: 5px;
        border: none;
        color: var(--accent);   
        border: 1px solid var(--accent);

        svg
        {
            font-size: 22px;
        }
    }
}