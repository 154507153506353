.bio1 {
  width: 100%;
  height: auto;
  background-color: var(--light-grey);
}
.bio1 .bio1_under {
  max-width: var(--wl);
  height: 100%;
  margin: auto;
  display: flex;
}
.bio1 .bio1_under .left1 {
  height: 100%;
  width: 512px;
}
.bio1 .bio1_under .left1 img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  pointer-events: none;
}
.bio1 .bio1_under .right1 {
  width: 768px;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.bio1 .bio1_under .right1 h1 {
  font-size: 28px;
  width: 80%;
  border-bottom: 1px solid var(--accent);
  padding-bottom: 5px;
}
.bio1 .bio1_under .right1 p {
  margin-top: 10px;
  color: var(--dark);
  font-weight: 500;
}
.bio1 .bio1_under .right1 .rs1 {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  text-align: justify;
  font-size: 20px;
  line-height: 30px;
  color: var(--grey);
}
.bio1 .bio1_under .right1 .rs1 span {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: black;
}
.bio1 .bio1_under .right1 .rs1 span a {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: var(--accent);
}

@media only screen and (max-width: 1180px) {
  .bio1 {
    width: 100%;
    height: auto !important;
    overflow-y: hidden;
  }
  .bio1 .bio1_under .left1 {
    width: 80%;
    height: auto !important;
    background-image: url("../images/apn-hero.webp");
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .bio1 .bio1_under .left1 img {
    display: none;
  }
  .bio1 .right1 {
    display: flex;
    justify-content: space-between !important;
  }
}
@media only screen and (max-width: 1000px) {
  .bio1 {
    height: auto !important;
    width: 100%;
  }
  .bio1 .bio1_under {
    flex-wrap: wrap;
  }
  .bio1 .bio1_under .left1 {
    width: 100% !important;
    height: 600px !important;
    display: flex !important;
    flex-direction: column !important;
    background-image: url("../images/apn-hero.webp");
    background-repeat: no-repeat;
    background-position: center;
  }
  .bio1 .bio1_under .left1 img {
    display: none;
  }
  .bio1 .bio1_under .right1 {
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    overflow-y: scroll;
    align-items: center;
  }
}/*# sourceMappingURL=hero.css.map */