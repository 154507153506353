.swiper-button-next,
.swiper-button-prev {
  color: var(--accent) !important;
  /* Text color of the button */
  width: 30px !important;
  height: 30px !important;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px !important;
  transition: var(--quick);

  &::after {
    font-size: 20px !important;
  }

  &:hover {
    background-color: var(--accent);
    color: white !important;
    transition: var(--quick);
  }
}

.swiper-pagination-bullet {
  background-color: var(--accent) !important;
  transform: translateY(8px);
}

.mainpage-wrapper {
  width: 100%;
  height: 100%;

  //firstsection------------------------------------------------------------->
  // .hero_carousel {
  //   width: 100%;
  //   height: auto;



      // h1 {
      //   width: 100%;
      //   height: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      //   user-select: none;

      //   span {
      //     color: rgba(0, 0, 0, 0.035);
      //     // border: 1px solid red;

      //     font-weight: 900;
      //     pointer-events: none;

      //     &:nth-child(1) {
      //       font-size: 300px;
      //       line-height: 70%;
      //     }

      //     &:nth-child(2) {
      //       font-size: 390px;
      //       letter-spacing: 18px;
      //       line-height: 75%;
      //     }

      //     &:nth-child(3) {
      //       font-size: 175px;
      //       line-height: 80%;
      //     }
      //   }
      // }

    // .mainpage-inner {
    //   height: 100%;
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   background: rgba(255, 255, 255, 0.9);
    //   max-width: var(--wl);
    //   margin: auto;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   flex-direction: column;

      // .bg {
      //   background-image: url("../images/flag1.png");
      //   background-position: center center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   height: 100%;
      //   margin: auto;
      //   width: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: -1;
      //   animation: herobg 5s linear infinite;
      //   animation-delay: 1s;
      // }

      // .image {
      //   width: 100%;
      //   height: 70%;
      //   background-image: url("../images/hero.png");
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   margin: auto;
      //   top: 20px;
      //   animation: hero 5s linear infinite;
      //   animation-delay: 1s;
      //   z-index: 2;
      // }
    // }

    p {
      z-index: 1;
      width: 100%;
      height: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: "Nexa", sans-serif;
      font-size: 45px;
      font-weight: 700;

      span {
        font-size: 45px;
        color: var(--accent);
        font-weight: 700;
      }
    }

    .smalltxt {
      font-size: 20px;
      font-weight: 500;
      height: 5%;
      display: flex;
      padding: 8px 0px 0px 0px;
      align-items: flex-start;
      font-family: "Nexa", sans-serif;
      line-height: 100%;
    }

    .buttons {
      z-index: 1;
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 100px;
      font-size: 45px;

      // border: 1px solid red;

      .btn {
        span {
          display: flex;

          svg {
            font-size: 18px;
            // border: 1px solid red;
          }
        }
      }
    }
  }

  //second section ------------------------------------------------------------------------->
  .second-section {
    width: 100%;
    // height: 460px;
    height: fit-content;
    padding: 40px 0px 80px 0px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h2 {
      text-align: center;
      font-size: 26px;
      padding-bottom: 40px;

      span {
        font-size: 26px;
      }
    }

    .second-main {
      max-width: var(--wl);
      height: 100%;
      gap: 100px;
      margin: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;

      p {
        width: 100%;
      }

      .card {
        width: 300px;
        height: 350px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        border-radius: 10px;
        position: relative;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.092);
        justify-content: center;
        overflow: hidden;
        cursor: pointer;

        p {
          font-size: 20px;
          color: white;
        }

        .grad {
          position: absolute;
          height: 100%;
          width: 100%;
          background: linear-gradient(transparent, white);
        }

        &:hover {
          .btn {
            transform: scale(1.05) translateY(-20px);
          }

          .grad {
            opacity: 0;
            transition: var(--slow);
          }
        }
      }
    }
  }

  // third section css--------------------------------------------------------------->

  .third-section {
    width: 100%;
    height: fit-content;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .swiper {
      max-width: var(--wl);
      // border: 1px solid red;
      height: 350px;
    }

    .swiperslide {
      text-align: center;
      font-size: 18px;
      color: var(--dark);
      background: #fff;
      display: flex;
      justify-content: center;
      border: 0.5px solid #dadada;
      flex-direction: column;
      border-radius: 10px;
      // margin-right: 134px!important;
      width: 300px !important;
      // height: 350px!important;

      img {
        object-fit: cover;
        width: 100%;
        height: 70%;
      }

      .title {
        overflow: hidden;
        justify-content: center;
        align-items: center;
        letter-spacing: 1px;
        font-family: "Nexa", sans-serif;
        font-size: 20px;
        text-overflow: ellipsis;
        padding: 10px;
        white-space: nowrap;
        color: var(--black);
        font-weight: 500;
        text-align: left !important;
      }

      .div {
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .date {
        position: absolute;
        top: 2%;
        right: 1%;
        border-radius: 20px;
        border: none;
        background: var(--accent);
        padding: 5px 10px;
        color: white;
      }

      .readmore {
        border: none;
        background: white;
        color: var(--accent);
        cursor: pointer;
      }
    }

    h2 {
      height: 15%;
      font-size: 26px;
      width: 100%;
      font-family: "Nexa", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding-bottom: 10px;

      span {
        color: var(--accent);
        font-family: "Nexa", sans-serif;
        font-size: 26px;
        margin-left: 5px;
        font-weight: 600;
      }
    }
  }

  // --------------------------------------------------->gallery sections
  .gallery_section {
    height: auto;
    padding-bottom: 40px;
    width: 100%;
    background: var(--light-grey);

    .gallery_main {
      height: 60vh;
      max-width: var(--wl);
      margin: auto;
      padding: 10px;

      .gtop {
        width: 100%;
        height: 10%;
        display: flex;
        margin: 10px auto;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        color: var(--black);
        font-family: "Nexa", sans-serif;
        font-weight: 600;

        span {
          color: var(--accent);
          font-family: "Nexa", sans-serif;
          font-size: 26px;
          margin-left: 5px;
          font-weight: 600;
        }
      }

      .parent {
        height: 90%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        .gallery_box {
          border-radius: 10px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: var(--quick);
          height: auto;

          &:hover {
            box-shadow: 0px 3px 20px #f97d09a2;
            transform: translateY(-3px);
          }
        }

        .gallery_div1 {
          grid-area: 1 / 1 / 2 / 2;
        }

        .gallery_div2 {
          grid-area: 2 / 1 / 3 / 2;
        }

        .gallery_div3 {
          grid-area: 3 / 1 / 4 / 2;
        }

        .gallery_div4 {
          grid-area: 1 / 2 / 3 / 3;
        }

        .gallery_div5 {
          grid-area: 2 / 3 / 4 / 4;
        }

        .gallery_div6 {
          grid-area: 1 / 3 / 2 / 4;
        }

        .gallery_div7 {
          grid-area: 3 / 2 / 4 / 3;
        }
      }
    }
  }


// animations
@keyframes updown {
  0% {
    top: 60px;
  }

  50% {
    top: 600px;
  }

  100% {
    top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .mainpage-wrapper .gallery_section .gallery_main .parent {
    display: flex !important;
    flex-direction: column !important;
    height: auto;
    gap: 20px;
    align-items: center;
    justify-content: center;

    .gallery_box {
      width: 90%;
      height: 400px;
    }
  }

  .mainpage-wrapper .gallery_section .gallery_main {
    width: 100%;
    height: fit-content;
  }
}

@media only screen and (max-width: 450px) {
  .mainpage-wrapper .gallery_section .gallery_main .parent .gallery_box {
    width: 90%;
    height: 350px;
  }
}

//styling with responsive hero

//styling with responsive hero
// @media only screen and (max-width: 1080px) {
//     .fourth_section {
//       width: 100%;
//       height: auto !important;
//       overflow-y: hidden;

//       .fourth_inner .fleft {
//         // display:none;
//         width: 30%;
//         height: auto !important;
//         background-image: url("../images/apn-hero.png");
//         background-repeat: no-repeat;
//         background-position: bottom;
//         img {
//           display: none;
//         }
//       }
//       .fright {
//         display: flex;
//         justify-content: space-between !important;
//         //   overflow-y: scroll;
//       }
//     }
//   }

//   @media only screen and (max-width: 1000px) {
//     .fourth_section {
//       height: auto !important;
//       width: 100%;
//       .fourth_inner {
//         .fleft {
//           width: 100% !important;
//           height: 600px !important;
//           display: flex !important;
//           flex-direction: column !important;
//           background-image: url("../images/apn-hero.png");
//           background-repeat: no-repeat;
//           background-position: center;
//           img {
//             display: none;
//           }
//         }
//         .fright {
//           width: 100% !important;
//           height: auto !important;
//           display: flex;
//           flex-direction: column;
//           justify-content: space-between !important;
//           overflow-y: scroll;
//           align-items: center;
//         }
//       }
//     }
//   }
//