     :root {
         //   --accent: #eb7924;
         --accent: #f97d09;
         --black: #1a1a1a;
         --white: #fff;
         --grey: rgb(125, 125, 125);
         --wl: 1280px;
         --quick: all 0.3s ease-out;
         --med: all 0.6s ease-out;
         --slow: all 1s ease-out;
         --opacity: 0.5;
         --dark: rgb(163, 161, 161);
         --light-grey: rgb(241, 241, 241);
     }

     *,
     *::after,
     *::before {
         box-sizing: border-box;
         margin: 0px;
         padding: 0px;
         font-family: 'Montserrat', sans-serif;
         font-size: 14px;
         scroll-behavior: smooth;
        //  border: 1px dotted blueviolet;

     }

     @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');
     @import url('https://fonts.cdnfonts.com/css/nexa-bold');

     @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Tiro+Devanagari+Marathi&display=swap');

     // font-family: 'Bebas Neue', sans-serif;
     // font-family: 'Montserrat', sans-serif;
     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
         color: var(--black);
         // font-family: 'Bebas Neue', sans-serif;
         font-family: 'Nexa', sans-serif;

         span {
             color: var(--accent);
             // font-weight: 800;
             // font-family: 'Bebas Neue', sans-serif;
             font-family: 'Nexa', sans-serif;
         }
     }

     .btn {
         width: fit-content;
         padding: 10px 30px;
         background: var(--accent);
         color: var(--white) !important;
         display: flex;
         align-items: center;
         gap: 5px;
         outline: none;
         border: none;
         transition: var(--quick);
         z-index: 1;
         text-decoration: none;
         text-align: center;
         font-weight: 500;
         border-radius: 5px;
         cursor: pointer;

         &:hover {
             transform: scale(1.05);

         }

     }

     .btn-hover {
         transform: translateY(-10px);
         box-shadow: 0px 5px 20px rgba(0, 0, 0, 0);

         &:hover {
             transform: translateY(-15px);
             box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.209);
         }
     }


     ::selection {
         color: rgb(255, 255, 255);
         background: var(--accent);
     }

     * {

         /* Customize the scrollbar for webkit-based browsers (Chrome, Safari) */
         /* You can adjust the styles as needed */
         ::-webkit-scrollbar {
             width: 5px;
             /* Set the width of the scrollbar */
         }

         ::-webkit-scrollbar-thumb {
             background: var(--accent);
             border-radius: 20px;
             /* Set the color of the scrollbar thumb */
         }

         /* Customize the scrollbar track */
         ::-webkit-scrollbar-track {
             background: var(--light-grey);
             /* Set the color of the scrollbar track */
         }

     }
     p,ul,li,ol
     {
        font-size: 16px !important;
        letter-spacing: 120% !important;
        line-height: 150% !important;
        font-weight: 300 !important;

     }
      
     li 
     {
        padding: 5px 0px;
        margin-left: 25px;

     }
     ul 
     {

        font-weight: 600 !important;
        font-size: 120% !important;
     }