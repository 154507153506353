// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
// #root {
//   text-align: center;
//   font-family: 'Roboto', sans-serif;
// }
.gallery-c {
    // min-width: var(--wl);
    margin-top: 80px;
    text-align: center;

    h1 {
        margin: 10px 0px;
        font-size: 1.7rem;
        padding: 2px;
        font-weight: 800;

        span {
            color: var(--accent);
        }
    }
}

.image-card {
    margin-top: 70px;
    width: 20vw;
    height: 20vw;
    object-fit: cover;
    margin: 15px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

#lightbox-img {
    margin-top: 40px;
    height: 70vh;
    max-width: 80vw;
    object-fit: cover;
}

#lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //   background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;

}



img:hover,
button:hover {
    cursor: pointer;

}


.gallery-parent {
    // min-width: var(--wl);
    // margin-top: 90px;
    // height: 100vh;
    width: 100%;

    .gallery-container {

        max-width: var(--wl);
        margin: auto;
        height: 100%;

        h2 {
            text-align: center !important;
            width: 100%;
            font-size: 1.9rem;
            // padding: 10px;
            font-weight: 800;
        }

        .gallery-box {
            height: 90%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            align-items: center;
            justify-content: center;

            h2 {
                .gallery {
                    font-size: 1.9rem;

                    color: var(--accent);
                    font-size: 1.9rem;
                }
            }

            .first-video {
                height: 210px !important;
                width: 364px !important;

                .video_player {
                    width: 100% !important;
                    height: 100% !important;
                }

                &:hover {
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
                }
            }

            .box-img {
                height: 220px;
                width: 260px;


                .vbox1 {
                    height: 220px !important;
                    width: 350px !important;
                }

                .vbox {
                    height: 215px;
                    width: 360px;

                    @keyframes fade-right {
                        0% {
                            transform: translateX(100px);
                            opacity: 0;
                        }

                        100% {
                            transform: translateX(0);
                            opacity: 1;
                        }
                    }



                }

                &:hover {
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
                }
            }

        }
    }

    .gap-gallery {
        height: 30px;
    }

}



////
/// 

.gallery-c {
    margin-top: 80px;
    text-align: center;

    h1 {
        margin: 10px 0px;
        font-size: 1.7rem;
        padding: 2px;
        font-weight: 800;
    }
}

.image-card {

    margin-top: 70px;
    width: 20vw;
    height: 20vw;
    object-fit: cover;
    margin: 15px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

#lightbox-img {
    margin-top: 40px;
    height: 70vh;
    max-width: 80vw;
    object-fit: cover;
}

#lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //   background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;

}


img:hover,
button:hover {
    cursor: pointer;

}