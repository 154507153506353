.marathiStyles {
  /* Add Marathi-specific CSS properties */
  font-family: "MarathiFont", Arial, sans-serif;
  line-height: 115% !important;
  /* Change color to red for Marathi */
  /* Add other Marathi styles */
}

.active:after {
  content: "";
  display: block;
  height: 3px;
  border-radius: 10px;
  width: 100%;
  background-color: var(--accent);
}

.theader-Wrapper {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.158) !important;
  background: white !important;
}
.theader-Wrapper .theader {
  max-width: var(--wl);
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.theader-Wrapper .theader .tleft {
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.theader-Wrapper .theader .tleft a,
.theader-Wrapper .theader .tleft .tabout-main {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--black);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
.theader-Wrapper .theader .tleft a .tabout-section,
.theader-Wrapper .theader .tleft .tabout-main .tabout-section {
  display: flex;
  flex-direction: column;
  position: absolute;
  list-style: none;
  gap: 10px;
  top: 150%;
  padding: 10px 0px;
  opacity: 0;
  pointer-events: none;
}
.theader-Wrapper .theader .tleft a.hover,
.theader-Wrapper .theader .tleft .tabout-main.hover {
  align-items: flex-start;
}
.theader-Wrapper .theader .tleft a.hover:after,
.theader-Wrapper .theader .tleft .tabout-main.hover:after {
  content: "";
  display: block;
  height: 3px;
  border-radius: 10px;
  width: 0%;
  transition: var(--quick);
  background-color: var(--accent);
}
.theader-Wrapper .theader .tleft a.hover:hover:after,
.theader-Wrapper .theader .tleft .tabout-main.hover:hover:after {
  width: 100%;
  transition: var(--quick);
}
.theader-Wrapper .theader .tright {
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.theader-Wrapper .theader .tright a,
.theader-Wrapper .theader .tright .tgallery-main {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: black;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.theader-Wrapper .theader .tright a.hover,
.theader-Wrapper .theader .tright .tgallery-main.hover {
  align-items: flex-start;
}
.theader-Wrapper .theader .tright a.hover:after,
.theader-Wrapper .theader .tright .tgallery-main.hover:after {
  content: "";
  display: block;
  height: 3px;
  border-radius: 10px;
  width: 0%;
  background-color: var(--accent);
  transition: var(--quick);
}
.theader-Wrapper .theader .tright a.hover:hover:after,
.theader-Wrapper .theader .tright .tgallery-main.hover:hover:after {
  width: 100%;
  transition: var(--quick);
}
.theader-Wrapper .theader .tlogo {
  height: 100%;
  width: 256px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
}
.theader-Wrapper .theader .tlogo .splogo {
  font-size: 18px;
  font-weight: 900 !important;
  line-height: 100%;
  color: black;
  line-height: 120%;
}
.theader-Wrapper .theader .tmenubutton {
  position: absolute;
  right: 5%;
  height: 70%;
  width: 40px;
  cursor: pointer;
  display: none;
}
.theader-Wrapper .theader .tmenubutton svg {
  width: 100%;
  height: 100%;
}
.theader-Wrapper .lang {
  position: absolute;
  right: 0;
  top: 20%;
}
.theader-Wrapper .lang button {
  cursor: pointer;
}
.theader-Wrapper .ttoggled-menu {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: white;
  transition: all 0.6s cubic-bezier(0.175, 0.882, 0.32, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.theader-Wrapper .ttoggled-menu .tinner_links_toggled {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.theader-Wrapper .ttoggled-menu .tinner_links_toggled a {
  text-decoration: none;
  font-size: 36px;
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.theader-Wrapper .ttoggled-menu .tinner_links_toggled a.hover:after {
  content: "";
  display: block;
  height: 3px;
  border-radius: 10px;
  width: 0%;
  background-color: var(--accent);
  transition: var(--quick);
}
.theader-Wrapper .ttoggled-menu .tinner_links_toggled a.hover:hover:after {
  width: 100%;
  transition: var(--quick);
}

@media only screen and (max-width: 800px) {
  .theader-Wrapper .theader {
    justify-content: space-between;
  }
  .theader-Wrapper .theader .tmenubutton {
    display: block;
  }
  .theader-Wrapper .theader .tleft {
    display: none;
  }
  .theader-Wrapper .theader .tright {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .theader-Wrapper .ttoggled-menu .tinner_links_toggled a {
    font-size: 30px;
  }
}/*# sourceMappingURL=headertwo.css.map */