.samarthwork-parent {
  align-items: stretch;
  height: 100%;
  width: 100%;
  margin-top: 65px;
  max-width: var(--wl);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.samarthwork-parent .samarth-heading {
  padding: 20px;
  font-size: 30px;
  color: black !important;
  text-align: center;
  margin: auto;
}
.samarthwork-parent .samarthbox {
  margin: auto;
  width: 100%;
  height: 100%;
  align-items: stretch;
  gap: 50px;
  display: flex;
  padding: 0px 15px;
  justify-content: space-between;
  overflow: hidden;
}
.samarthwork-parent .samarthbox .samarthwork-left {
  height: auto;
  align-items: stretch;
  width: 55%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  overflow: hidden;
}
.samarthwork-parent .samarthbox .samarthwork-left .samarthwork-content-box {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-items: stretch;
}
.samarthwork-parent .samarthbox .samarthwork-left .samarthwork-content-box .samarthwork-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  color: black !important;
  overflow: hidden;
}
.samarthwork-parent .samarthbox .samarthwork-left .samarthwork-content-box .samarthwork-content .samarth-body {
  font-size: 14px;
  color: black !important;
  text-align: justify;
  overflow: hidden;
}
.samarthwork-parent .samarthbox .samarthwork-right {
  align-items: stretch;
  height: auto;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 0px 0px;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
}
.samarthwork-parent .samarthbox .samarthwork-right .samarthwork-right-container {
  padding: 20px;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url(../images/samarth/s1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  align-items: stretch;
}

.feature-parent {
  background-color: var(--light-grey);
}
.feature-parent .features-container {
  max-width: var(--wl);
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.feature-parent .features-container .ul_heading {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding: 5px;
}

.samarth-counter_parent {
  height: auto;
  width: 100%;
  background-color: var(--light-grey);
}
.samarth-counter_parent .samarth-counter-container {
  max-width: var(--wl);
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: a;
  height: auto;
  display: flex;
  gap: 10px;
  padding: 20px 0px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
}
.samarth-counter_parent .samarth-counter-container .samarth-booth-counter-heading {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.samarth-counter_parent .samarth-counter-container .samarth-counter-box {
  overflow: hidden;
  width: auto;
  height: auto;
  display: flex;
  gap: 10px;
  padding: 20px 5px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
}
.samarth-counter_parent .samarth-counter-container .samarth-counter-box .counter_wrapper {
  text-align: center;
  align-items: center;
  color: black;
  width: 150px;
  border: none !important;
}

@media only screen and (max-width: 1200px) {
  .samarthwork-parent {
    margin: auto;
    height: auto;
  }
  .samarthwork-parent .samarth-heading {
    margin-top: 65px;
  }
  .samarthwork-parent .samarthbox {
    padding: 20px;
    width: 100%;
    height: auto;
    margin: auto;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .samarthwork-parent .samarthbox .samarthwork-left {
    height: 45%;
    width: 100%;
    align-items: center;
  }
  .samarthwork-parent .samarthbox .samarthwork-left .samarthwork-content-box {
    padding: auto;
  }
  .samarthwork-parent .samarthbox .samarthwork-right {
    height: 500px;
    width: 100%;
    overflow: hidden;
  }
}
.sashaktikaran-parent {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: var(--wl);
}
.sashaktikaran-parent .sashaktikaran-heading {
  font-size: 30px;
  color: black;
  text-align: center;
}
.sashaktikaran-parent .sashaktikaran-box {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  padding: 24px;
  gap: 50px;
  margin-top: 10px;
}
.sashaktikaran-parent .sashaktikaran-box .sashaktikaran-left {
  height: auto;
  align-items: stretch;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 0px 0px;
  flex-direction: column;
  gap: 30px;
}
.sashaktikaran-parent .sashaktikaran-box .sashaktikaran-left .sashaktikaran-left-container {
  border-radius: 20px;
  align-items: stretch;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url(../images/herosection/hero4.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.sashaktikaran-parent .sashaktikaran-box .sashaktikaran-right {
  width: 60%;
  height: auto;
}
.sashaktikaran-parent .sashaktikaran-box .sashaktikaran-right .sashaktikaran-content {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  text-align: justify;
  color: black;
  height: auto;
  align-items: stretch;
}
.sashaktikaran-parent .sashaktikaran-box .sashaktikaran-right .sashaktikaran-content .sashaktikaran-body {
  font-size: 14px;
  color: black !important;
  text-align: justify;
  align-items: stretch;
}

@media only screen and (max-width: 1200px) {
  .sashaktikaran-parent {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .sashaktikaran-parent .sashaktikaran-box {
    width: 100%;
    height: 100%;
    margin: auto;
    flex-direction: column;
    display: flex;
    padding: 24px;
    gap: 20px;
  }
  .sashaktikaran-parent .sashaktikaran-box .sashaktikaran-left {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
  }
  .sashaktikaran-parent .sashaktikaran-box .sashaktikaran-left .sashaktikaran-left-container {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .sashaktikaran-parent .sashaktikaran-box .sashaktikaran-right {
    width: 100%;
    height: 100%;
  }
  .sashaktikaran-parent .sashaktikaran-box .sashaktikaran-right .sashaktikaran-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    text-align: justify;
    color: black;
  }
}
.section-gap {
  height: 50px;
  width: 100%;
}/*# sourceMappingURL=samarth.css.map */