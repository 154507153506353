.gallery-c {
  margin-top: 80px;
  text-align: center;
}
.gallery-c h1 {
  margin: 10px 0px;
  font-size: 1.7rem;
  padding: 2px;
  font-weight: 800;
}
.gallery-c h1 span {
  color: var(--accent);
}

.image-card {
  margin-top: 70px;
  width: 20vw;
  height: 20vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 15px;
}
.image-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#lightbox-img {
  margin-top: 40px;
  height: 70vh;
  max-width: 80vw;
  -o-object-fit: cover;
     object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img:hover,
button:hover {
  cursor: pointer;
}

.gallery-parent {
  width: 100%;
}
.gallery-parent .gallery-container {
  max-width: var(--wl);
  margin: auto;
  height: 100%;
}
.gallery-parent .gallery-container h2 {
  text-align: center !important;
  width: 100%;
  font-size: 1.9rem;
  font-weight: 800;
}
.gallery-parent .gallery-container .gallery-box {
  height: 90%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.gallery-parent .gallery-container .gallery-box h2 .gallery {
  font-size: 1.9rem;
  color: var(--accent);
  font-size: 1.9rem;
}
.gallery-parent .gallery-container .gallery-box .first-video {
  height: 210px !important;
  width: 364px !important;
}
.gallery-parent .gallery-container .gallery-box .first-video .video_player {
  width: 100% !important;
  height: 100% !important;
}
.gallery-parent .gallery-container .gallery-box .first-video:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.gallery-parent .gallery-container .gallery-box .box-img {
  height: 220px;
  width: 260px;
}
.gallery-parent .gallery-container .gallery-box .box-img .vbox1 {
  height: 220px !important;
  width: 350px !important;
}
.gallery-parent .gallery-container .gallery-box .box-img .vbox {
  height: 215px;
  width: 360px;
}
@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.gallery-parent .gallery-container .gallery-box .box-img:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.gallery-parent .gap-gallery {
  height: 30px;
}

.gallery-c {
  margin-top: 80px;
  text-align: center;
}
.gallery-c h1 {
  margin: 10px 0px;
  font-size: 1.7rem;
  padding: 2px;
  font-weight: 800;
}

.image-card {
  margin-top: 70px;
  width: 20vw;
  height: 20vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 15px;
}
.image-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#lightbox-img {
  margin-top: 40px;
  height: 70vh;
  max-width: 80vw;
  -o-object-fit: cover;
     object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img:hover,
button:hover {
  cursor: pointer;
}/*# sourceMappingURL=videogallery.css.map */