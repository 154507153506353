#form-phone::-webkit-inner-spin-button,
#form-phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-parent-v1 {
  height: auto;
  background: var(--white);
  padding: 60px 0px;

  .contact-container {
    display: flex;
    max-width: var(--wl);
    padding: 0px 15px;
    margin: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    // justify-content: space-between;

    .contact-left {
      height: 80%;
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 100px 0px 30px 30px;
      // aspect-ratio: 1 / 1;

      .form_wrapper {
        // margin-top: 60px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .form_main {
          width: 450px;
          height: 450px;
          border: 1px solid rgba(255, 255, 255, 0.149);
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          padding: 20px;
          background: transparent;
          margin: 20px;

          .box1 {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            h3 {
              color: var(--dark);
              font-size: 30px;
            }
          }

          .form {
            width: 100%;
            // height: 450px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .input-icon-wrap {
              width: 95%;
              border: 1px solid #ddd;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              outline: 1px solid var(--accent);
              box-shadow: 0px 0xp 50px orange;
              // &:focus {

              //     outline: 1px solid var(--accent);
              //     box-shadow: 0px 0xp 50px orange;
              // }
              .input-icon {
                svg {
                  color: var(--accent);
                  padding: 5px;
                  font-size: 28px;
                  // color: var(--accent);
                  // border-right: 2px solid var(--gray)
                }
              }

              input,
              textarea {
                width: 100%;
                padding: 10px 20px;
                border: 1px solid rgba(255, 255, 255, 0.149);
                outline: none;
                color: var(--black);
                transition: var(--quick);
                background: rgba(255, 255, 255, 0.089);
                font-size: 16px;
                caret-color: var(--accent);
                // &:focus {

                //     outline: 1px solid var(--accent);
                //     box-shadow: 0px 0xp 50px orange;
                // }
                ::placeholder {
                  display: none;
                }
              }
            }
          }

          .input-icont {
            margin-top: -49px;
            color: var(--accent);

            svg {
              font-size: 24px !important;
            }
          }
        }
      }
    }

    .contact-right {
      height: 100%;
      width: 45%;
      color: var(--black);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      // gap: 20px;

      .contact-right-container {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        // margin-top: 100px;

        .contact-address-box {
          height: auto;
          width: 400px;
          display: flex;
          gap: 20px;
          flex-direction: column;

          .contact_icon_container {
            border: 1px solid var(--accent);
            padding: 10px;
            height: auto;
            width: auto;
            // justify-content: space-around;
            h2 {
              line-height: 15px;
              display: flex;
              align-items: center;

              span {
                color: var(--black);
                height: 100%;
                display: flex;
                align-items: center;
              }

              .contact-icons {
                // display: flex;
                svg {
                  color: var(--dark);
                  margin: 0px 15px;
                  font-size: 20px;

                  &:hover {
                    color: var(--accent);
                  }
                }
              }

              .contact-h {
                font-size: 17px;
                // padding-top: 75px;
                // font-weight: 700;
              }
            }

            .contactp {
              margin-left: 50px;
              color: var(--dark);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-parent-v1 {
    height: auto;

    .contact-container {
      flex-direction: column;

      .contact-left {
        margin-top: 0px;
        width: 100%;
        align-items: center;
        padding: 50px 0px 0px 0px;

        .form_wrapper {
          margin-top: 0px;
        }
      }
      .form_wrapper .form_main {
        padding: 0px !important;
        margin: 0px !important;
      }

      .contact-right {
        margin-top: 0px;
        // height: 100%;
        width: 100%;
        padding: 0px;
        // margin: 100px 0px 0px 0px;
        // margin: 40px auto 40px auto;
        overflow: hidden;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .contact-parent-v1 {
    height: auto;

    .contact-container {
      flex-direction: column;
      .contact-address-box .contact_icon_container .contactp {
        font-size: 12px;
      }
    }
  }
  .contact-parent-v1 .contact-container .contact-right .contact-right-container .contact-address-box 
  {
    width: 90% !important;
    word-wrap: break-word;
  }
}

// /
.form_wrapper {
  margin-top: 60px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;

  justify-content: center;

  .form_main {
    width: 450px;
    height: 600px;
    border: 1px solid rgba(255, 255, 255, 0.149);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background: transparent;
    margin: 20px;

    .box1 {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      h3 {
        color: var(--dark);
        font-size: 30px;
      }
    }

    .form {
      width: 100%;
      // height: 450px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .input-icon-wrap {
        width: 95%;
        border: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        outline: 1px solid var(--accent);
        box-shadow: 0px 0xp 50px orange;
        // &:focus {

        //     outline: 1px solid var(--accent);
        //     box-shadow: 0px 0xp 50px orange;
        // }
        .input-icon {
          svg {
            color: var(--accent);
            padding: 5px;
            font-size: 28px;
            // color: var(--accent);
            // border-right: 2px solid var(--gray)
          }
        }

        input,
        textarea {
          width: 100%;
          padding: 10px 20px;
          border: 1px solid rgba(255, 255, 255, 0.149);
          outline: none;
          color: var(--black);
          transition: var(--quick);
          background: rgba(255, 255, 255, 0.089);
          font-size: 16px;
          caret-color: var(--accent);
          // &:focus {

          //     outline: 1px solid var(--accent);
          //     box-shadow: 0px 0xp 50px orange;
          // }
          ::placeholder {
            display: none;
          }
        }
      }
    }

    .input-icont {
      margin-top: -49px;
      color: var(--accent);

      svg {
        font-size: 24px !important;
      }
    }
  }
}