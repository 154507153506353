.floating_contact_wrapper {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 80px;
  right: 10px;
  z-index: 10000000000000000;
}
.floating_contact_wrapper .con_btn {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-grey);
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  color: var(--accent);
  border: 1px solid var(--accent);
}
.floating_contact_wrapper .con_btn svg {
  font-size: 22px;
}/*# sourceMappingURL=floatingcontact.css.map */