.swiper-button-next,
.swiper-button-prev {
  color: var(--accent) !important;
  /* Text color of the button */
  width: 30px !important;
  height: 30px !important;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px !important;
  transition: var(--quick);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: var(--accent);
  color: white !important;
  transition: var(--quick);
}

.swiper-pagination-bullet {
  background-color: var(--accent) !important;
  transform: translateY(8px);
}

.mainpage-wrapper {
  width: 100%;
  height: 100%;
}
.mainpage-wrapper p {
  z-index: 1;
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Nexa", sans-serif;
  font-size: 45px;
  font-weight: 700;
}
.mainpage-wrapper p span {
  font-size: 45px;
  color: var(--accent);
  font-weight: 700;
}
.mainpage-wrapper .smalltxt {
  font-size: 20px;
  font-weight: 500;
  height: 5%;
  display: flex;
  padding: 8px 0px 0px 0px;
  align-items: flex-start;
  font-family: "Nexa", sans-serif;
  line-height: 100%;
}
.mainpage-wrapper .buttons {
  z-index: 1;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
  font-size: 45px;
}
.mainpage-wrapper .buttons .btn span {
  display: flex;
}
.mainpage-wrapper .buttons .btn span svg {
  font-size: 18px;
}

.second-section {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 40px 0px 80px 0px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.second-section h2 {
  text-align: center;
  font-size: 26px;
  padding-bottom: 40px;
}
.second-section h2 span {
  font-size: 26px;
}
.second-section .second-main {
  max-width: var(--wl);
  height: 100%;
  gap: 100px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.second-section .second-main p {
  width: 100%;
}
.second-section .second-main .card {
  width: 300px;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.092);
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
.second-section .second-main .card p {
  font-size: 20px;
  color: white;
}
.second-section .second-main .card .grad {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(transparent, white);
}
.second-section .second-main .card:hover .btn {
  transform: scale(1.05) translateY(-20px);
}
.second-section .second-main .card:hover .grad {
  opacity: 0;
  transition: var(--slow);
}

.third-section {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.third-section .swiper {
  max-width: var(--wl);
  height: 350px;
}
.third-section .swiperslide {
  text-align: center;
  font-size: 18px;
  color: var(--dark);
  background: #fff;
  display: flex;
  justify-content: center;
  border: 0.5px solid #dadada;
  flex-direction: column;
  border-radius: 10px;
  width: 300px !important;
}
.third-section .swiperslide img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 70%;
}
.third-section .swiperslide .title {
  overflow: hidden;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-family: "Nexa", sans-serif;
  font-size: 20px;
  text-overflow: ellipsis;
  padding: 10px;
  white-space: nowrap;
  color: var(--black);
  font-weight: 500;
  text-align: left !important;
}
.third-section .swiperslide .div {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.third-section .swiperslide .date {
  position: absolute;
  top: 2%;
  right: 1%;
  border-radius: 20px;
  border: none;
  background: var(--accent);
  padding: 5px 10px;
  color: white;
}
.third-section .swiperslide .readmore {
  border: none;
  background: white;
  color: var(--accent);
  cursor: pointer;
}
.third-section h2 {
  height: 15%;
  font-size: 26px;
  width: 100%;
  font-family: "Nexa", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-bottom: 10px;
}
.third-section h2 span {
  color: var(--accent);
  font-family: "Nexa", sans-serif;
  font-size: 26px;
  margin-left: 5px;
  font-weight: 600;
}

.gallery_section {
  height: auto;
  padding-bottom: 40px;
  width: 100%;
  background: var(--light-grey);
}
.gallery_section .gallery_main {
  height: 60vh;
  max-width: var(--wl);
  margin: auto;
  padding: 10px;
}
.gallery_section .gallery_main .gtop {
  width: 100%;
  height: 10%;
  display: flex;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: var(--black);
  font-family: "Nexa", sans-serif;
  font-weight: 600;
}
.gallery_section .gallery_main .gtop span {
  color: var(--accent);
  font-family: "Nexa", sans-serif;
  font-size: 26px;
  margin-left: 5px;
  font-weight: 600;
}
.gallery_section .gallery_main .parent {
  height: 90%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.gallery_section .gallery_main .parent .gallery_box {
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: var(--quick);
  height: auto;
}
.gallery_section .gallery_main .parent .gallery_box:hover {
  box-shadow: 0px 3px 20px rgba(249, 125, 9, 0.6352941176);
  transform: translateY(-3px);
}
.gallery_section .gallery_main .parent .gallery_div1 {
  grid-area: 1/1/2/2;
}
.gallery_section .gallery_main .parent .gallery_div2 {
  grid-area: 2/1/3/2;
}
.gallery_section .gallery_main .parent .gallery_div3 {
  grid-area: 3/1/4/2;
}
.gallery_section .gallery_main .parent .gallery_div4 {
  grid-area: 1/2/3/3;
}
.gallery_section .gallery_main .parent .gallery_div5 {
  grid-area: 2/3/4/4;
}
.gallery_section .gallery_main .parent .gallery_div6 {
  grid-area: 1/3/2/4;
}
.gallery_section .gallery_main .parent .gallery_div7 {
  grid-area: 3/2/4/3;
}

@keyframes updown {
  0% {
    top: 60px;
  }
  50% {
    top: 600px;
  }
  100% {
    top: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .mainpage-wrapper .gallery_section .gallery_main .parent {
    display: flex !important;
    flex-direction: column !important;
    height: auto;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  .mainpage-wrapper .gallery_section .gallery_main .parent .gallery_box {
    width: 90%;
    height: 400px;
  }
  .mainpage-wrapper .gallery_section .gallery_main {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media only screen and (max-width: 450px) {
  .mainpage-wrapper .gallery_section .gallery_main .parent .gallery_box {
    width: 90%;
    height: 350px;
  }
}/*# sourceMappingURL=mainpage.css.map */