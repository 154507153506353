.gallery-c {
  margin-top: 80px;
  text-align: center;
  max-width: var(--wl);
  margin: auto;
  background: white;
}
.gallery-c .gallery-btn-container {
  margin: auto;
}
.gallery-c .gallery-btn-container .my-btn {
  padding: 10px 30px;
  color: white;
  align-items: center;
  font-size: 15x;
  background-color: var(--accent);
  margin: 20px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: var(--quick);
}
.gallery-c .gallery-btn-container .my-btn:hover {
  box-shadow: 0px 0px 20px rgb(218, 155, 39);
}
.gallery-c .gallery-btn-container .sudoactive {
  padding: 10px 30px;
  color: var(--accent);
  border: 1px solid var(--accent);
  align-items: center;
  font-size: 15x;
  background-color: transparent;
  margin: 20px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  transition: var(--quick);
}
.gallery-c .gallery-btn-container .sudoactive:hover {
  box-shadow: 0px 0px 20px rgb(218, 155, 39);
}
.gallery-c h1 {
  margin: 10px 0px;
  font-size: 1.9rem;
  padding: 2px;
  font-weight: 800;
  padding-top: 80px;
}
.gallery-c h1 span {
  font-size: 1.8rem;
  color: var(--accent);
}

.image-card {
  margin-top: 70px;
  width: 20vw;
  height: 20vw;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 15px;
}
.image-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#lightbox-img {
  margin-top: 40px;
  height: 70vh;
  max-width: 80vw;
  -o-object-fit: cover;
     object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img:hover,
button:hover {
  cursor: pointer;
}

.gallery_parent_box {
  display: flex;
  height: auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.clickbtn {
  width: 50px;
  height: 50px;
}/*# sourceMappingURL=gallery.css.map */