

.gallery-c {
  background: #fdfdfd;
  text-align: center;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 1.7rem;
    padding: 2px;
    font-weight: 800;
  }
}

.image-card {
  margin-top: 70px;
  border: 1px solid var(--accent);
  width: 350px!important;
  height: 350px!important;
  border-radius: 10px;
  object-fit: cover;
  margin: 15px;
  &:hover {
    box-shadow: 0 14px 28px rgba(253, 173, 53, 0.25), 0 10px 10px rgba(230, 124, 4, 0.22);
  }
}

#lightbox-img {
  padding: 5px;
  margin-top: 40px;
  height: auto;
  max-width: auto;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  background-color: #1b1a1aa4;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clickbtn {
  color: white;
  border: 2px solid var(--dark);
  background-color: var(--accent);
  font-size: 1.2rem;
}

img:hover,
button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .image-card {
    width: 90vw !important;
    height: 40vh !important;
  }
}
.card_box 
{
  display: flex;
  max-width: var(--wl);
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
}