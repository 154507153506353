.footer {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.26);
  padding-top: 100px;
}
.footer .footer_inner {
  max-width: var(--wl);
  height: 100%;
  margin: auto;
  padding-top: 10px;
}
.footer .footer_inner .top {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  padding-bottom: 20px;
}
.footer .footer_inner .top .part1 {
  width: 320px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer .footer_inner .top .part1 .fotlogo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer .footer_inner .top .part1 .fotlogo p {
  display: flex;
  align-items: center;
}
.footer .footer_inner .top .part1 .fotlogo p {
  font-size: 18px;
  font-weight: bold !important;
  line-height: 100%;
}
.footer .footer_inner .top .part1 .part1res {
  font-size: 14px;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  color: var(--dark);
}
.footer .footer_inner .top .part2 {
  width: 250px;
  height: 100%;
  display: flex;
}
.footer .footer_inner .top .part3 {
  width: 250px;
  height: 100%;
}
.footer .footer_inner .top .part4 {
  width: 250px;
  height: 100%;
}
.footer .footer_inner .top .part {
  display: flex;
  flex-direction: column;
}
.footer .footer_inner .top .part .part_top {
  width: 100%;
  height: 20%;
  padding-bottom: 10px;
}
.footer .footer_inner .top .part .part_top p {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 20px;
}
.footer .footer_inner .top .part .part_bottom {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.footer .footer_inner .top .part .part_bottom a {
  text-decoration: none;
  color: var(--dark);
  font-size: 14px;
  font-weight: 400;
  transition: var(--quick);
}
.footer .footer_inner .top .part .part_bottom a:hover {
  color: var(--accent);
}
.footer .footer_inner .top .part .part_bottom p {
  font-size: 14px !important;
  color: var(--dark);
  font-weight: 300 !important;
}
.footer .footer_inner .bottom {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 40px 10px 40px;
}
.footer .footer_inner .bottom .copy {
  font-size: 14px;
  line-height: 100%;
  margin-top: 3px;
  color: var(--dark);
}
.footer .footer_inner .bottom .copy a {
  text-decoration: none;
  color: var(--dark);
  font-size: 14px;
  font-weight: 400;
  transition: var(--quick);
}
.footer .footer_inner .bottom .icons {
  width: 200px;
  height: auto;
  display: flex;
  justify-content: space-evenly;
}
.footer .footer_inner .bottom .icons svg {
  cursor: pointer;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.856);
  transition: var(--quick);
}
.footer .footer_inner .bottom .icons svg:hover {
  color: var(--accent);
}

@media only screen and (max-width: 1100px) {
  .top {
    padding: 0px 20px 0px 10px;
    gap: 20px;
  }
}
@media only screen and (max-width: 834px) {
  .top {
    width: calc((100% - 50px) / 2);
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media only screen and (max-width: 610px) {
  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top .part1 {
    padding-top: 10px;
    width: 90% !important;
  }
  .top .part2 {
    padding-top: 10px;
    width: 90% !important;
  }
  .top .part3 {
    padding-top: 10px;
    width: 90% !important;
  }
  .top .part4 {
    padding-top: 10px;
    width: 90% !important;
  }
}/*# sourceMappingURL=footer.css.map */