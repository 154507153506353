.social-parent {
    width: 100%;
    height: auto;
    background: var(--light-grey);
    padding: 20px 0px;

    .social-container {
        max-width: var(--wl);
        margin: auto;

        justify-content: space-evenly;

        h2 {
            text-align: center;
            font-size: 26px;
            padding-bottom: 40px;

            span {
                font-size: 26px;
            }
        }

        .card-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            gap: 20px;
            flex-wrap: wrap;

            .card {
                display: flex;
                flex-direction: column;
                border: 1px solid;
                width: 350px;
                padding: 5px;
                border-radius: 5px;
                 
                h3 {
                    
                    font-size: 20px;
                    margin-bottom: 10px;
                    text-align: center;
                }
                    &:nth-child(1)
                    {
                        border: 1px solid #1773EA;
                        h3{
                        color: #1773EA;
                        }
                    }
                    &:nth-child(2)
                    {
                        border: 1px solid rgb(211,47,143);
                        h3{
                        color: rgb(211,47,143) !important;
                        }
                    }
                    &:nth-child(3)
                    {
                        border: 1px solid #0A66C2;;
                        h3{color: #0A66C2;}
                        
                    }
                
            }
        }
    }
}