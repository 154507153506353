// fourthsection------------------------------------------------------>>
.bio1 {
    width: 100%;
    height: auto;
    background-color: var(--light-grey);
  
    .bio1_under {
      max-width: var(--wl);
      height: 100%;
      margin: auto;
      display: flex;
      //   flex-wrap: wrap;
  
      .left1 {
        height: 100%;
        width: 512px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          pointer-events: none;
        }
      }
  
      .right1 {
        // height: 100%;
        width: 768px;
        padding: 20px;
        justify-content: center;
        display: flex;
        flex-direction: column; //new style
        // box-shadow: 0px 2px 20px #797674a2;
        overflow-y: scroll;
        h1 {
          font-size: 28px;
          width: 80%;
          border-bottom: 1px solid var(--accent);
          padding-bottom: 5px;
        }
  
        p {
          margin-top: 10px;
          color: var(--dark);
          font-weight: 500;
        }
  
        .rs1 {
          width: 100%;
          height: fit-content;
          text-align: justify; //add new style
          font-size: 20px;
          line-height: 30px;
          color: var(--grey);
  
          span {
            font-size: 18px;
            font-weight: 500;
            text-decoration: none;
            color: black;
  
            a {
              font-size: 18px;
              font-weight: 500;
              text-decoration: none;
              color: var(--accent);
            }
          }
        }
      }
    }
  }
  //styling with responsive hero
  @media only screen and (max-width: 1180px) {
    .bio1 {
      width: 100%;
      height: auto !important;
      overflow-y: hidden;
  
      .bio1_under .left1 {
        // display:none;
        width: 80%;
        height: auto !important;
        background-image: url("../images/apn-hero.webp");
        background-repeat: no-repeat;
        background-position: bottom;
        img {
          display: none;
        }
      }
      .right1 {
        display: flex;
        justify-content: space-between !important;
        //   overflow-y: scroll;
      }
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .bio1 {
      height: auto !important;
      width: 100%;
      .bio1_under {
        flex-wrap: wrap;
        .left1 {
          width: 100% !important;
          height: 600px !important;
          display: flex !important;
          flex-direction: column !important;
          background-image: url("../images/apn-hero.webp");
          background-repeat: no-repeat;
          background-position: center;
          img {
            display: none;
          }
        }
        .right1 {
          width: 100% !important;
          height: auto !important;
          display: flex;
          flex-direction: column;
          justify-content: space-between !important;
          overflow-y: scroll;
          align-items: center;
        }
      }
    }
  }