.samarthwork-parent {
    // height: 100vh;
    align-items: stretch;
    height: 100%;
    // margin:auto;
    width: 100%;
    // margin: 65px 55px 0px 55px;
    margin-top: 65px;
    max-width: var(--wl);
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;


    .samarth-heading {
        padding: 20px;
        font-size: 30px;
        color: black !important;
        text-align: center;
        margin: auto;
    }

    .samarthbox {
        margin: auto;
        width: 100%;
        height: 100%;
        align-items: stretch;
        gap: 50px;
        display: flex;
        padding: 0px 15px;
        justify-content: space-between;
        overflow: hidden;

        // .samarth-heading {
        //     padding: 20px;
        //     font-size: 30px;
        //     color: black !important;
        //     text-align: center;
        // }

        .samarthwork-left {
            // height: 100%
            height: auto;
            align-items: stretch;
            width: 55%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            overflow: hidden;

            .samarthwork-content-box {
                // height: 100%;
                width: 100%;
                height: auto;
                margin: auto;
                display: flex;
                padding: 24px;
                overflow: hidden;
                align-items: stretch;
                .samarthwork-content {

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 20px;
                    color: black !important;
                    overflow: hidden;

                    .samarth-body {
                        font-size: 14px;
                        // letter-spacing: 0.4px;
                        // line-height: 20px;
                        // font-weight: 400 !important;
                        // word-spacing: 3px;
                        color: black !important;
                        text-align: justify;
                        overflow: hidden;
                    }
                   
                }
            }
        }

        .samarthwork-right {
            // height: 100%;
            align-items: stretch;
            height:auto;
            width: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0px 0px 0px;
            flex-direction: column;
            gap: 30px;
            overflow: hidden;

            .samarthwork-right-container {
                padding: 20px;
                border-radius: 20px;
                // height: 450px !important;
                // height: auto!important;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-image: url(../images/samarth/s1.webp);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;
                align-items: stretch;

            }
        }
    }
}



//Features
.feature-parent{
    background-color: var(--light-grey);
.features-container {
    max-width: var(--wl);
    padding: 20px;
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: column;

    .ul_heading {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        padding: 5px;
    }
}

    // ul {
    //     li {
    //         border: 1px solid var(--light-grey);
    //         box-shadow: 2px 2px 3px #c4bfbf;

    //     }
    // }

  
}



//  counter

.samarth-counter_parent {
    height: auto;
    width: 100%;
    background-color: var(--light-grey);
    .samarth-counter-container {
        max-width: var(--wl);
        height: auto;
        margin: auto;
        // flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        .samarth-booth-counter-heading {
            text-align: center;
            font-size: 25px;
            font-weight: 600;
        }

        
        overflow: hidden;
        width: a;
        height: auto;
        display: flex;
        gap: 10px;
        padding: 20px 0px;
        align-items: center;
        // justify-content: space-between;
        justify-content: space-around;
        flex-wrap: wrap;
        //  border: 1px solid red;
        display: flex;

        // margin: auto;
        // width: var(--wl);
        .samarth-counter-box {

            overflow: hidden;
            width: auto;
            height: auto;
            display: flex;
            gap: 10px;
            padding: 20px 5px;
            align-items: center;

            justify-content: space-around;
            flex-wrap: wrap;
            // background: var(--accent);
            display: flex;

            .counter_wrapper {
                text-align: center;
                align-items: center;
                // border: 1px solid var(--accent);
                color: black;
                width: 150px;
                border: none !important;

            }
        }
    }

}



@media only screen and (max-width: 1200px) {
    .samarthwork-parent {
        margin: auto;
        height: auto;

        .samarth-heading {
            margin-top: 65px;
        }

        .samarthbox {
            padding: 20px;
            width: 100%;
            // height: 100%;
            height: auto;
            margin: auto;
            flex-direction: column-reverse;
            gap: 20px;

            .samarthwork-left {
                height: 45%;
                width: 100%;
                align-items: center;

                // padding: 100px 0px 0px 0px;                
                .samarthwork-content-box {
                    padding: auto;

                }
            }



            .samarthwork-right {
                // height: 45%;
                height: 500px;
                width: 100%;
                // margin: 40px auto 40px auto;
                overflow: hidden;
            }

        }


    }
}



//************* samarthbox1****************

.sashaktikaran-parent {
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: var(--wl);

    .sashaktikaran-heading {
        font-size: 30px;
        color: black;
        text-align: center;
    }

    // max-width: var(--wl);
    .sashaktikaran-box {
        height: 100%;
        width: 100%;
        margin: auto;
        display: flex;
        padding: 24px;
        gap: 50px;
        margin-top: 10px;

        .sashaktikaran-left {
            // height: 100%;
            height: auto;
            align-items: stretch;
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px 0px 0px;
            flex-direction: column;
            gap: 30px;

            .sashaktikaran-left-container {
                border-radius: 20px;
                // height: 450px;
                align-items: stretch;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-image: url(../images/herosection/hero4.webp);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

            }

        }

        .sashaktikaran-right {
            width: 60%;
height: auto;
            .sashaktikaran-content {
                display: flex;
                width: 100%;
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                text-align: justify;
                color: black;
height: auto;
align-items: stretch;
                .sashaktikaran-body {
                    font-size: 14px;
                    // letter-spacing: 0.4px;
                    // line-height: 20px;
                    // font-weight: 400 !important;
                    // word-spacing: 3px;
                    color: black !important;
                    text-align: justify;
                    align-items: stretch;
                }
            }

        }
    }

}

@media only screen and (max-width: 1200px) {



    .sashaktikaran-parent {
        width: 100%;
        height: 100%;
        margin: auto;

    
        .sashaktikaran-box {
            width: 100%;
            height: 100%;
            margin: auto;
            flex-direction: column;
            display: flex;
            padding: 24px;
            gap: 20px;

            .sashaktikaran-left {
                height: 500px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 40px 0px 0px 0px;
                flex-direction: column;
                gap: 30px;

                .sashaktikaran-left-container {
                    border-radius: 20px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;


                }

            }

            .sashaktikaran-right {
                width: 100%;
                height: 100%;

                .sashaktikaran-content {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 20px;
                    text-align: justify;
                    color: black;

                }

            }
        }

    }
}

.section-gap {
    height: 50px;
    width: 100%;
}