.action-parent {
  width: 100%;
  padding: 80px 0px 100px 0px;
  .action {
    height: auto;
    padding: 20px 0px;

    h1 {
      font-size: 30px;
      text-align: center;

      font-weight: 800;
    }

  }

  .card-parent {
    margin: 0px 50px 0px 50px;
    display: flex;
    max-width: var(--wl);
    height: auto;
    justify-content: space-evenly;
    align-items: stretch !important;
    margin: auto;
    flex-wrap: wrap;
    gap: 20px;
  }

  .card {
    width: 350px;
    overflow: hidden;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    box-shadow: 0px 0px 20px rgba(202, 202, 202);

    img {
      overflow: hidden;
      width: 100%;
      height: 280px;
    }

    .card-title {
      font-size: 20px;
      overflow: hidden;
      font-weight: 600;
      padding: 10px 0px 10px 0px;
    }

    .card-text {
      padding: 5px 0px 15px 0px;
      text-align: center;
    }

    .card-body {
      display: flex !important;
      align-items: center !important;
      flex-direction: column !important;
    }
  }
}
.loader_spinner 
{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
}