.social-parent {
  width: 100%;
  height: auto;
  background: var(--light-grey);
  padding: 20px 0px;
}
.social-parent .social-container {
  max-width: var(--wl);
  margin: auto;
  justify-content: space-evenly;
}
.social-parent .social-container h2 {
  text-align: center;
  font-size: 26px;
  padding-bottom: 40px;
}
.social-parent .social-container h2 span {
  font-size: 26px;
}
.social-parent .social-container .card-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}
.social-parent .social-container .card-box .card {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  width: 350px;
  padding: 5px;
  border-radius: 5px;
}
.social-parent .social-container .card-box .card h3 {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.social-parent .social-container .card-box .card:nth-child(1) {
  border: 1px solid #1773EA;
}
.social-parent .social-container .card-box .card:nth-child(1) h3 {
  color: #1773EA;
}
.social-parent .social-container .card-box .card:nth-child(2) {
  border: 1px solid rgb(211, 47, 143);
}
.social-parent .social-container .card-box .card:nth-child(2) h3 {
  color: rgb(211, 47, 143) !important;
}
.social-parent .social-container .card-box .card:nth-child(3) {
  border: 1px solid #0A66C2;
}
.social-parent .social-container .card-box .card:nth-child(3) h3 {
  color: #0A66C2;
}/*# sourceMappingURL=socialfeed.css.map */