* {
    // border: 1px solid red;
}

.marathiStyles {
    /* Add Marathi-specific CSS properties */
    font-family: 'MarathiFont', Arial, sans-serif;
    //   color: #FF0000!important; 
    line-height: 115% !important;
    /* Change color to red for Marathi */
    /* Add other Marathi styles */
}

.active {
    // align-items: flex-start;

    &:after {
        content: "";
        display: block;
        height: 3px;
        border-radius: 10px;
        width: 100%;
        background-color: var(--accent);
        
    }
}


.theader-Wrapper {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.158) !important;
    background: white !important;

    .theader {
        max-width: var(--wl);
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: relative;

        .tleft {
            height: 100%;
            width: 40%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;


            a,
            .tabout-main {
                text-decoration: none;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                gap: 2px;
                color: var(--black);
                // font-weight: 500;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: black;

                .tabout-section {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    list-style: none;
                    gap: 10px;
                    top: 150%;
                    padding: 10px 0px;
                    opacity: 0;
                    pointer-events: none;

                }

                &.hover {

                    align-items: flex-start;

                    &:after {
                        content: "";
                        display: block;
                        height: 3px;
                        border-radius: 10px;
                        width: 0%;
                        transition: var(--quick);
                        background-color: var(--accent);
                    }

                    &:hover {
                        &:after {
                            width: 100%;
                            transition: var(--quick);

                        }
                    }

                }
            }
        }

        .tright {
            height: 100%;
            width: 40%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;


            a,
            .tgallery-main {
                text-decoration: none;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                gap: 2px;
                color: black;
                height: 100%;
                align-items: center;
                justify-content: center;

                &.hover {
                    align-items: flex-start;

                    &:after {
                        content: "";
                        display: block;
                        height: 3px;
                        border-radius: 10px;
                        width: 0%;
                        background-color: var(--accent);
                        transition: var(--quick);
                    }

                    &:hover {
                        &:after {
                            width: 100%;
                            transition: var(--quick);

                        }
                    }

                }
            }

        }

        .tlogo {
            height: 100%;
            width: 256px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-decoration: none;

            .splogo {
                font-size: 18px;
                font-weight: 900 !important;
                line-height: 100%;
                color: black;
                line-height: 120%;
            }
        }

        .tmenubutton {
            position: absolute;
            right: 5%;
            height: 70%;
            width: 40px;
            cursor: pointer;
            display: none;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .lang {
        position: absolute;
        right: 0;
        top: 20%;

        button {
            cursor: pointer;
        }
    }

    .ttoggled-menu {
        position: absolute;
        width: 100%;
        height: 100vh;
        background: white;
        transition: all .6s cubic-bezier(0.175, 0.882, 0.32, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;

        .tinner_links_toggled {
            height: 100%;
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;

            a {
                text-decoration: none;
                font-size: 36px;
                display: flex;
                flex-direction: column;
                color: black;
                align-items: center;
                justify-content: center;
                font-weight: bold;

                &.hover {


                    &:after {
                        content: "";
                        display: block;
                        height: 3px;
                        border-radius: 10px;
                        width: 0%;
                        background-color: var(--accent);
                        transition: var(--quick);
                    }

                    &:hover {
                        &:after {
                            width: 100%;
                            transition: var(--quick);

                        }
                    }

                }
            }

        }

    }
}

// @media only screen and (max-width: 1200px) {
//     .header-Wrapper {
//         .header {
//             justify-content: flex-start;
//             padding: 10px 20px;

//             .logo {}

//             .left {
//                 display: none;
//             }

//             .right {
//                 display: none;
//             }
//         }
//     }
// }

@media only screen and (max-width: 800px) {
    .theader-Wrapper {
        .theader {
            justify-content: space-between;  
            .tmenubutton {
                display: block;
            }

            .tleft {
                display: none;
            }

            .tright {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .theader-Wrapper {

        .ttoggled-menu {
            .tinner_links_toggled {
                a {
                    font-size: 30px;
                }
            }
        }



    }
}